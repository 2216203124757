<template>
  <section class="login-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 login-sec" v-if="page === 'login'">
          <h2 class="text-center">Login</h2>
          <form class="login-form" @submit.prevent="login()">
            <div id="login-response">
              <b-alert
                :show="alert.visible"
                fade
                dismissible
                @dismissed="hideAlert()"
                :variant="alert.type"
              >
                {{ alert.msg }}
              </b-alert>
            </div>

            <div class="form-group">
              <label for="email">Email</label>
              <input
                id="email"
                type="text"
                class="form-control"
                placeholder="e.g. john.smith@gmail.com"
                v-model="form.email"
              />
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input
                id="password"
                type="password"
                class="form-control"
                placeholder="e.g. hunter2"
                v-model="form.password"
              />
            </div>
            <div class="login-buttons text-right">
              <b-button
                variant="link"
                class="mt-2"
                :to="{ name: 'login', params: { page: 'reset' } }"
              >
                Forgot Password
              </b-button>
              <button
                id="loginbutton"
                type="submit"
                class="btn btn-primary mt-2"
              >
                Login
              </button>
              <button
                id="googlebutton"
                type="button"
                class="btn btn-google mt-2 ml-3"
                @click="googleLogin()"
              >
                <i class="fab fa-google"></i> Google Sign-In
              </button>
              <button
                id="facebookbutton"
                type="button"
                class="btn btn-facebook mt-2 ml-3"
                @click="facebookLogin()"
              >
                <i class="fab fa-facebook-f"></i> Facebook Sign-In
              </button>
            </div>
          </form>
        </div>
        <div class="col-md-4 login-sec" v-if="page === 'reset'">
          <h2 class="text-center">Password Reset</h2>
          <form class="login-form" @submit.prevent="sendReset">
            <div id="login-response">
              <b-alert
                :show="alert.visible"
                fade
                dismissible
                @dismissed="hideAlert()"
                :variant="alert.type"
              >
                {{ alert.msg }}
              </b-alert>
              <p v-if="!alert.visible">
                Please enter your email and hit Send Reset. If an account exists
                with that email, a password reset email will be sent.
              </p>
            </div>

            <div class="form-group">
              <input
                id="email"
                type="text"
                class="form-control"
                placeholder="e.g. john.smith@gmail.com"
                v-model="form.email"
              />
            </div>
            <div class="login-buttons">
              <button
                id="resetbutton"
                type="submit"
                class="btn btn-primary float-right"
              >
                Send Reset
              </button>
            </div>
          </form>
        </div>

        <div class="col-lg-8 d-none d-lg-block banner-sec">
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner" role="listbox">
              <div class="carousel-item active">
                <img
                  class="d-block img-fluid"
                  src="../assets/cooking-760px.jpg"
                  alt="First slide"
                />
                <div class="carousel-caption d-none d-md-block">
                  <div class="banner-text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import firebase from "firebase";
import FirebaseMixin from "../mixins/Firebase";
import UtilsMixin from "../mixins/Utils";
import Users from "../firebase/users";

export default {
  mixins: [FirebaseMixin, UtilsMixin],
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      alert: {
        type: "",
        msg: "",
        visible: false
      }
    };
  },
  computed: {
    page() {
      return this.$route.params.page && this.$route.params.page === "reset"
        ? "reset"
        : "login";
    }
  },
  methods: {
    ...mapMutations(["setUser", "setSearchKey"]),
    login() {
      if (this.form.email === "") {
        this.displayAlert("Please enter your email.", "error");
        return false;
      }
      if (this.form.password === "") {
        this.displayAlert("Please enter your password.", "error");
        return false;
      }
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(user => {
          this.displayAlert("Login successful.", "success");
          console.log(user);
          this.setUser(user.user);
          this.navigate("recipes");
          let u = new Users();
          u.onLogin().then(resp => {
            console.log(resp);
            this.setSearchKey(resp.data.searchKey);
          });
        })
        .catch(err => {
          this.displayAlert("Login failed.", "error");
          console.log(err.message);
        });
    },
    googleLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
      firebase.auth().signInWithRedirect(provider);
    },
    facebookLogin() {
      const provider = new firebase.auth.FacebookAuthProvider();
      firebase.auth().signInWithRedirect(provider);
    },
    sendReset() {
      if (this.form.email === "") {
        this.displayAlert("Please enter your email.", "error");
        return false;
      }
      firebase
        .auth()
        .sendPasswordResetEmail(this.form.email)
        .then(() => {
          this.displayAlert(
            "Password reset processed. Please check your email.",
            "success"
          );
        })
        .catch(error => {
          console.log(error);
          this.displayAlert(
            "An error occurred while processing the request. Please try again later.",
            "error"
          );
        });
    },
    displayAlert(message, type = null) {
      this.alert.type =
        type === "error" ? "danger" : type === null ? "info" : type;
      this.alert.msg = message;
      this.alert.visible = true;
    },
    hideAlert() {
      this.alert.visible = false;
    }
  },
  created() {
    // Handle OAuth redirect
    firebase
      .auth()
      .getRedirectResult()
      .then(result => {
        console.log(result);
        if (result.credential) {
          const user = {
            uid: result.user.uid,
            name: result.user.displayName,
            email: result.user.email
          };
          this.setUser(user);
          this.navigate("recipes");
          let u = new Users();
          u.onLogin().then(resp => {
            console.log(resp);
            this.setSearchKey(resp.data.searchKey);
          });
        }
      })
      .catch(error => {
        // Handle Errors here.
        var errorCode = error.code;
        console.log(error);
        if (errorCode === "auth/account-exists-with-different-credential") {
          this.displayAlert(
            "Login failed: Account exists with different credentials.",
            "error"
          );
        } else {
          this.displayAlert("Login failed.", "error");
        }
      });
  }
};
</script>

<style scoped>
.login-block {
  width: 100%;
  padding: 50px 0;
}

#name {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

#login-response {
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.login-buttons {
  margin-top: 20px;
}

.btn-google {
  color: #fff;
  background-color: #dd4b39;
  width: 168px;
}
.btn-google:focus,
.btn-google.focus {
  color: #fff;
  background-color: #c23321;
}
.btn-google:hover {
  color: #fff;
  background-color: #c23321;
}
.btn-google.disabled:hover,
.btn-google[disabled]:hover,
fieldset[disabled] .btn-google:hover,
.btn-google.disabled:focus,
.btn-google[disabled]:focus,
fieldset[disabled] .btn-google:focus,
.btn-google.disabled.focus,
.btn-google[disabled].focus,
fieldset[disabled] .btn-google.focus {
  background-color: #dd4b39;
}

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  width: 168px;
}
.btn-facebook:focus,
.btn-facebook.focus {
  color: #fff;
  background-color: #2d4373;
}
.btn-facebook:hover {
  color: #fff;
  background-color: #2d4373;
}
.btn-facebook.disabled:hover,
.btn-facebook[disabled]:hover,
fieldset[disabled] .btn-facebook:hover,
.btn-facebook.disabled:focus,
.btn-facebook[disabled]:focus,
fieldset[disabled] .btn-facebook:focus,
.btn-facebook.disabled.focus,
.btn-facebook[disabled].focus,
fieldset[disabled] .btn-facebook.focus {
  background-color: #3b5998;
}

.banner-sec {
  background: url(../assets/cooking-760px.jpg) no-repeat left bottom;
  background-size: cover;
  min-height: 500px;
  border-radius: 0 10px 10px 0;
  padding: 0;
}

.banner-sec img {
  min-height: 500px;
  object-fit: cover;
  object-position: left;
}

.login-block .container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.carousel-inner {
  border-radius: 0 10px 10px 0;
}

.carousel-caption {
  text-align: left;
  left: 5%;
}

.login-sec {
  padding: 30px;
}

.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}

.login-sec .copy-text i {
  color: #feb58a;
}
.login-sec .copy-text a {
  color: #e36262;
}

.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: #46b6ac;
}

.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: rgb(255, 152, 0);
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}

.btn-login {
  background: #de6262;
  color: #fff;
  font-weight: 600;
}

.banner-text {
  width: 70%;
  position: absolute;
  bottom: 40px;
  padding-left: 20px;
}

.banner-text h2 {
  color: #fff;
  font-weight: 600;
}

.banner-text h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #fff;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
}

.banner-text p {
  color: #fff;
}

.loginbox {
  margin: 100px 20px;
}

body .zocial {
  margin: 8px 4px;
  font-size: 13px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.alert {
  margin-bottom: 0;
  border-radius: 0;
  /*padding: 8px;*/
}

.alert-dismissible .close {
  top: -3px;
}

.alert-button {
  padding: 0;
  line-height: 1;
  margin-bottom: 3px;
  font-weight: 600;
}
</style>
